import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`The page header arranges elements that apply context and provide navigation for the current page.`}</p>
    <p>{`The elements within page header are rearranged and displayed accordingly depending on the `}<a parentName="p" {...{
        "href": "/foundations/layout#viewport-ranges"
      }}>{`viewport size`}</a>{` and context of the page.`}</p>
    <img src="https://user-images.githubusercontent.com/586552/222194474-74b72ae3-f8d4-4336-9a2c-9d5c18d91c42.png" role="presentation" width="960" alt="Page header component" />
    <h2>{`Anatomy`}</h2>
    <p>{`Page header can include a context bar, title bar, description, and contextual navigation (pertaining to the current page).`}</p>
    <p>{`By default, the `}<a parentName="p" {...{
        "href": "#context-bar"
      }}>{`context bar`}</a>{` is visible on narrow viewports only, the `}<a parentName="p" {...{
        "href": "#title-bar"
      }}>{`title bar`}</a>{` is visible on all viewports, and the leading action and trailing action are visible on regular viewports.`}</p>
    <img src="https://github.com/primer/design/assets/1446503/a17ac8aa-e7e5-45fd-9e03-00bbb7dcff75" role="presentation" width="993" alt="Page header anatomy" />
    <h2>{`Context bar`}</h2>
    <p>{`The context bar is intended to set context for the user on smaller viewports and provide any actions that fit within the context bar. As a reminder, by default, the context bar (including its actions) is only visible on narrow viewports.`}</p>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Parent link
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      The default way to let users navigate up in the hierarchy on narrow viewports.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194471-dfc56e9a-a2ab-48a0-8a04-41ebb5ec4adf.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Context bar actions
    </Text>
    <Text as="p" mt="1" mdxType="Text">
      Actions that appear in the context bar specifically.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194470-da8126b8-72c5-4078-968e-ebc5c2bf3140.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Context bar area
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      The context bar is a generic slot for any component above the title region. Use it for custom breadcrumbs and
      other navigation elements instead of parent link.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194469-85131849-4a7a-41e6-abca-040d042110df.png" role="presentation" width="456" />
    </Box>
    <h2>{`Title bar`}</h2>
    <p>{`The title bar can include a static page title, actions, and leading and trailing visuals.`}</p>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Default title variant
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Most common page title size. Use for static titles in most situations.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194467-1031b33b-81e7-429b-9201-d154fd7e9d59.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Large title variant
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Use large for for user-generated content such as issues, pull requests, or discussions.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194464-6c477db6-bb11-445e-b27e-41a0c7267758.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Subtitle variant
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Use subtitle when a title is already present in the page, such as in a Split page layout.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222215097-79bb0d77-af5c-4199-81a1-65d98a7a0556.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Leading visual
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Leading visuals are optional and appear at the start of the title. They can be octicons, avatars, and other custom
      visuals that fit a small area. Leading visuals remain visible on all viewports.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194458-922bd3bf-475d-4d76-97a1-bd8cda6af6f5.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Trailing visual
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Trailing visual and trailing text can display auxiliary information. They're placed at the right of the item, and
      can denote status, privacy details, etc. Trailing visuals remain visible on all viewports.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194459-daf8c4b8-0452-40a2-bdf3-2b74be06e7ef.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Leading action
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      A back button can be used as a leading action for local navigation. On narrow viewports, use parent link instead.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222503965-dc2d7727-6a45-402a-abb0-948a58866017.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="1" mdxType="Box">
    <Text as="h3" fontSize={2} m={0} mdxType="Text">
      Trailing action
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Trailing action can display subtler actions aligned directly next to the title. On narrow viewports, replace the
      trailing action with an item in an overflow button.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/586552/222194453-0f3e29b8-28cb-4623-8039-f546e1ae6622.png" role="presentation" width="456" />
    </Box>
    <h2>{`Accessibility`}</h2>
    <h3>{`Landmark usage`}</h3>
    <p>{`Pageheader allows usage of different HTML landmarks to create a more accessible UI.
The usage of `}<inlineCode parentName="p">{`header`}</inlineCode>{` and `}<inlineCode parentName="p">{`nav`}</inlineCode>{` regions are allowed within PageHeader, both with different use cases.`}</p>
    <p>{`If the `}<inlineCode parentName="p">{`PageHeader`}</inlineCode>{` is not contained within a landmark, consider using the `}<inlineCode parentName="p">{`role`}</inlineCode>{` prop to indicate one and provide it with an accessible name (perhaps the same as `}<inlineCode parentName="p">{`PageHeader.Title`}</inlineCode>{`) via `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<PageHeader role="banner" aria-label="Page Title">
    <PageHeader.TitleArea>
      <PageHeader.Title>Page Title</PageHeader.Title>
    </PageHeader.TitleArea>
  ...
</PageHeader>
`}</code></pre>
    <h4>{`PageHeader with `}<inlineCode parentName="h4">{`header`}</inlineCode>{` landmark`}</h4>
    <p>{`When utilizing landmarks, it's best to ensure that you’re using them correctly. For the header landmark you should follow these guidelines.`}</p>
    <ul>
      <li parentName="ul">{`Only utilize `}<inlineCode parentName="li">{`header`}</inlineCode>{` if one does not currently exist on the page.`}</li>
      <li parentName="ul">{`Utilize `}<inlineCode parentName="li">{`header`}</inlineCode>{` if the `}<inlineCode parentName="li">{`PageHeader`}</inlineCode>{` consists of site-oriented content, typically existing at the top of the page.`}</li>
      <li parentName="ul">{`Ensure that when used on `}<inlineCode parentName="li">{`PageHeader`}</inlineCode>{`, that the component itself is not within other landmarks, such as `}<inlineCode parentName="li">{`main`}</inlineCode>{`.`}</li>
    </ul>
    <h4>{`PageHeader with `}<inlineCode parentName="h4">{`nav`}</inlineCode>{` landmark`}</h4>
    <p>{`Navigation landmarks are best utilized to group links that pertain to navigation of the page.
You can have multiple navigation landmarks, but it’s important to ensure that each unique navigation element has a unique label attached, either via `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`.
You can utilize `}<inlineCode parentName="p">{`PageHeader.Navigation`}</inlineCode>{` as a `}<inlineCode parentName="p">{`navigation`}</inlineCode>{` landmark through the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop.
This further extends the accessibility of this component, as it allows users to navigate via landmarks easier.
It’s important to note that if you utilize a component with an existing `}<inlineCode parentName="p">{`navigation`}</inlineCode>{` landmark such as `}<inlineCode parentName="p">{`UnderlineNav`}</inlineCode>{` then you should not use `}<inlineCode parentName="p">{`PageHeader.Navigation`}</inlineCode>{` as a navigation landmark, as this would be a duplicate.`}</p>
    <p>{`For navigation, you should follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`If you have a list or grouping of links within `}<inlineCode parentName="li">{`PageHeader.Navigation`}</inlineCode>{`, you should utilize the `}<inlineCode parentName="li">{`nav`}</inlineCode>{` type for this element.`}</li>
      <li parentName="ul">{`If you’re already utilizing a component with a navigation landmark (such as `}<inlineCode parentName="li">{`UnderlineNav`}</inlineCode>{`), you should not use `}<inlineCode parentName="li">{`PageHeader.Navigation`}</inlineCode>{` as a navigation landmark.`}</li>
      <li parentName="ul">{`If more than one `}<inlineCode parentName="li">{`navigation`}</inlineCode>{` landmark exists on the page at a given time, you should add a unique label to each one.
You can do this by either passing an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` attribute to `}<inlineCode parentName="li">{`PageHeader.Navigation`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Heading Structure`}</h3>
    <p><inlineCode parentName="p">{`PageHeader.Title`}</inlineCode>{` renders its content as a heading in the background and most of the time it will have crucial information about the page, so it’s important to ensure that you’re using it in an accessible manner.`}</p>
    <p><inlineCode parentName="p">{`PageHeader.Title`}</inlineCode>{` is shipped as an `}<inlineCode parentName="p">{`H2`}</inlineCode>{` by default, but only if you do not pass a heading level to it via the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop. You should modify the level of this component if the default heading `}<inlineCode parentName="p">{`H2`}</inlineCode>{` does not make sense with the existing heading structure on the page.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="PageHeader" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      